// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"5ab187c355db4d805adc8616c0712a95dca0e98d"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import * as Sentry from '@sentry/nextjs';

Sentry.init({
    dsn: 'https://c4c0b25df7204ffab3c27ff8c4801ddb@glitchtip.akgirls.cz/1',

    // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
    tracesSampleRate: 1,
    attachStacktrace: true,
    normalizeDepth: 10,
    integrations: [
        Sentry.breadcrumbsIntegration({
            console: true,
            dom: true,
            fetch: true,
            history: true,
            xhr: true
        })
    ],

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false
});
